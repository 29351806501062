<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.card/add')" addName="新增类型" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.card_name" size="small" clearable placeholder="按名称查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-button size="small" plain @click="detail()" v-if="$hasAccess('market.card/record')">购买记录</el-button>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="card_id" title="ID" align="center" min-width="20px" />
			<vxe-table-column slot="table-item" field="card_name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="road" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.road||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="type" title="类型" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{types.type[row.type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="card_money" title="面值" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="price_money" title="售价" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="rbt_amount" title="返利金额/电量" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="add_vip_day" title="送VIP天" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="card_type" title="有效期类型" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{types.card_type[row.card_type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="day" title="有效期(天)" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="statustype" title="可购区域" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{types.city[row.city]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="statustype" title="状态" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{types.status[row.status]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="is_spread" title="是否推广" align="center" min-width="50px">
				<template v-slot="{row}">
					<span>{{row.is_spread?'是':'否'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.card/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="editOut(row)" v-if="$hasAccess('market.card/editout')">发放
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增电卡" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="600px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="100px" class="form">
				<el-form-item label="电卡名称" prop="card_name">
					<el-input v-model="addDialogFormData.card_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="addDialogFormData.road" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio :label="10">金额</el-radio>
						<el-radio :label="20">电量</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="面值" prop="card_money">
					<el-input-number :min="0" :step="100" v-model="addDialogFormData.card_money" clearable />
				</el-form-item>
				<el-form-item label="售价" prop="price_money">
					<el-input-number :min="1" :step="1" :precision="2" v-model="addDialogFormData.price_money"
						clearable />
				</el-form-item>
				<el-form-item label="返利金额" prop="rbt_amount" v-if="addDialogFormData.type==10">
					<el-input-number :min="0" :step="1" :precision="2" v-model="addDialogFormData.rbt_amount"
						clearable />
				</el-form-item>
				<el-form-item label="返利电量" prop="rbt_amount" v-if="addDialogFormData.type==20">
					<el-input-number :min="0" :step="10" :precision="2" v-model="editDialogFormData.rbt_amount"
						clearable />
				</el-form-item>
				<el-form-item label="赠送VIP天数" prop="add_vip_day" v-if="addDialogFormData.card_type==10">
					<el-input-number :precision="0" :min="0" :step="30" v-model="addDialogFormData.add_vip_day" clearable />
				</el-form-item>
				<el-form-item label="有效期类型" prop="card_type">
					<el-radio-group v-model="addDialogFormData.card_type">
						<el-radio :label="10">限时</el-radio>
						<el-radio :label="20">普通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time" v-if="addDialogFormData.card_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2" v-if="addDialogFormData.card_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time2"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="有效天数" prop="day" v-if="addDialogFormData.card_type==10">
					<el-input-number :min="1" :step="1" v-model="addDialogFormData.day" clearable />
				</el-form-item>
				<el-form-item label="可购区域" prop="city">
					<el-radio-group v-model="addDialogFormData.city">
						<el-radio :label="10">全部</el-radio>
						<el-radio :label="20">苏州</el-radio>
						<el-radio :label="30">无锡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-radio-group v-model="addDialogFormData.status">
						<el-radio :label="1">有效</el-radio>
						<el-radio :label="2">失效</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="addDialogFormData.is_spread" active-value='1' inactive-value='0'></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.card/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑优惠" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="600px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="电卡名称" prop="card_name">
					<el-input v-model="editDialogFormData.card_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="road">
					<el-select v-model="editDialogFormData.road" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio :label="10">金额</el-radio>
						<el-radio :label="20">电量</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="面值" prop="card_money">
					<el-input-number :min="0" :step="100" v-model="editDialogFormData.card_money" clearable />
				</el-form-item>
				<el-form-item label="售价" prop="price_money">
					<el-input-number :min="1" :step="1" :precision="2" v-model="editDialogFormData.price_money"
						clearable />
				</el-form-item>
				<el-form-item label="返利金额" prop="rbt_amount" v-if="editDialogFormData.type==10">
					<el-input-number :min="0" :step="1" :precision="2" v-model="editDialogFormData.rbt_amount"
						clearable />
				</el-form-item>
				<el-form-item label="返利电量" prop="rbt_amount" v-if="editDialogFormData.type==20">
					<el-input-number :min="0" :step="10" :precision="2" v-model="editDialogFormData.rbt_amount"
						clearable />
				</el-form-item>
				<el-form-item label="赠送VIP天数" prop="add_vip_day" v-if="editDialogFormData.card_type==10">
					<el-input-number :precision="0" :min="0" :step="30" v-model="editDialogFormData.add_vip_day" clearable />
				</el-form-item>
				<el-form-item label="有效期类型" prop="card_type">
					<el-radio-group v-model="editDialogFormData.card_type">
						<el-radio :label="10">限时</el-radio>
						<el-radio :label="20">普通</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time" v-if="editDialogFormData.card_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.start_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2" v-if="editDialogFormData.card_type==20">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.end_time"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="有效天数" prop="day" v-if="editDialogFormData.card_type==10">
					<el-input-number :min="1" :step="1" v-model="editDialogFormData.day" clearable />
				</el-form-item>
				<el-form-item label="可购区域" prop="city">
					<el-radio-group v-model="editDialogFormData.city">
						<el-radio :label="10">全部</el-radio>
						<el-radio :label="20">苏州</el-radio>
						<el-radio :label="30">无锡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-radio-group v-model="editDialogFormData.status">
						<el-radio :label="1">有效</el-radio>
						<el-radio :label="2">失效</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否推广" prop="is_spread">
					<el-switch v-model="editDialogFormData.is_spread" active-value='1' inactive-value='0'></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					:disabled="editDisabled" v-if="$hasAccess('market.card/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 发放 -->
		<el-dialog title="发放" :visible.sync="outDialogShow" :close-on-click-modal="false" :close-on-press-escape="false"
			width="600px">
			<el-form :model="outDialogFormData" :rules="rules" ref="outForm" label-width="150px" class="form">
				<el-form-item label="用户ID" prop="fans_id">
					<el-input v-model="outDialogFormData.fans_id" placeholder="支持多账户,用英文','将账号分开,且不要有空格"
						style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="数量" prop="card_num">
					<el-input-number :min="1" :max="10" :step="1" v-model="outDialogFormData.card_num" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveOut()"
					:disabled="editDisabled" v-if="$hasAccess('market.card/editout')">提 交
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-card-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '电卡',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				selecttime: {},
				types: {
					card_type: {
						10: '限时',
						20: '普通'
					},
					type: {
						10: '金额',
						20: '电量'
					},
					city: {
						10: '全部',
						20: '苏州',
						30: '无锡'
					},
					status: {
						1: '有效',
						2: '失效'
					}
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				rules: {
					card_name: [{
						required: true,
						message: '请输入电卡名称',
						trigger: 'change'
					}],
					road: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					card_money: [{
						required: true,
						message: '请输入面值',
						trigger: 'change'
					}],
					card_type: [{
						required: true,
						message: '请选择有效期类型',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
					price_money: [{
						required: true,
						message: '请输入售价',
						trigger: 'change'
					}],
					day: [{
						required: true,
						message: '请输入有效天数',
						trigger: 'change'
					}]
				},
				outDialogShow: false,
				outDialogFormData: {},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['card_id', 'card_name', 'card_money', 'price_money', 'card_type', 'type']
			}
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false
					}
				}
				return false
			}
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
		},
		methods: {
			// 初始化请求
			init() {},
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Market.GetCardList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.addDialogFormData = {
					card_type: 10,
					type: 10,
					city: 10,
					status: 1,
					is_spread: 0,
					rbt_amount: 0,
				}
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				this.outDialogFormData = {}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.addDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					card_id: row.card_id
				}
				const res = await this.$api.Market.GetCardDetail(params)
				if (res) {
					const {
						card_id,
						card_name,
						card_money,
						price_money,
						rbt_amount,
						add_vip_day,
						card_type,
						type,
						start_time,
						end_time,
						day,
						city,
						status,
						is_spread,
						road
					} = res
					this.editDialogFormData = {
						card_id,
						card_name,
						card_money,
						price_money,
						rbt_amount,
						add_vip_day,
						card_type,
						type,
						start_time,
						end_time,
						day,
						city,
						status,
						is_spread:is_spread.toString(),
						road,
					}
					this.editDialogFormOriData = {
						card_id,
						card_name,
						card_money,
						price_money,
						rbt_amount,
						add_vip_day,
						card_type,
						type,
						start_time,
						end_time,
						day,
						city,
						status,
						is_spread:is_spread.toString(),
						road,
					}
				}
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该电卡吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						card_id: row.card_id
					}
					await this.$api.Market.DeleteCard(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
				this.outDialogShow = false
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate(async valid => {
					if (valid) {
						if (this.addDialogFormData.select_time != undefined) {
							this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time
								.getTime() / 1000);
							this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2
								.getTime() / 1000);
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.addDialogFormData)
						}
						await this.$api.Market.AddCard(params)
						this.$notify({
							title: '成功',
							message: '新增成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 购买记录
			detail() {
				this.$router.push({
					name: "market-card-record",
					query: {

					},
				});
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						if (this.editDialogFormData.card_type == 20) {
							this.selecttime.start_time = this.editDialogFormData.start_time.length ==
								undefined ? parseInt(this.editDialogFormData.start_time.getTime() / 1000) :
								this.editDialogFormData.start_time;
							this.selecttime.end_time = this.editDialogFormData.end_time.length == undefined ?
								parseInt(this.editDialogFormData.end_time.getTime() / 1000) : this
								.editDialogFormData.end_time;
							this.editDialogFormData.start = this.selecttime.start_time;
							this.editDialogFormData.end = this.selecttime.end_time;
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Market.EditCard(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			//发放
			async editOut(row) {
				this.resetFormData()
				this.outDialogFormData.card_id = row.card_id;
				this.outDialogShow = true
			},
			// 提交发放代金券保存
			saveOut() {
				this.$refs.outForm.validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.outDialogFormData)
						}
						await this.$api.Market.OutCard(params)
						this.$notify({
							title: '成功',
							message: '发放成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
